<template>
    <div class="container content" v-if="activeUser.type == 'admin'">
        <div class="tile is-ancestor" >
            <div class="tile is-parent is-4">
                <article class="tile is-child box">
                    <p class="title">Koulutussetelien arvo yht</p>
                    <p class="summa">{{ activeUser.saldo.balance_total }}€</p>
                    <p>
                        <b>Työsuhde-etu:</b>
                        {{ activeUser.saldo.balance_tyosuhde_etu }} €<br /><b
                            >Muutosturva:</b
                        >
                        {{ activeUser.saldo.balance_muutosturva }} €
                    </p>
                </article>
            </div>
            <div class="tile is-parent is-4">
                <article class="tile is-child box">
                    <p class="title">Tilitettävä arvo yht</p>
                    <p class="summa">{{ activeUser.saldo.used_total }}€</p>
                    <p>
                        <b>Työsuhde-etu:</b>
                        {{ activeUser.saldo.used_tyosuhde_etu }} €<br /><b
                            >Muutosturva:</b
                        >
                        {{ activeUser.saldo.used_muutosturva }} €
                    </p>
                </article>
            </div>
            <div class="tile is-parent is-4">
                <article class="tile is-child box">
                    <p class="title">Vanhentunut arvo yht</p>
                    <p class="summa">{{ activeUser.saldo.expired_total }}€</p>
                    <p>
                        <b>Työsuhde-etu:</b>
                        {{ activeUser.saldo.expired_tyosuhde_etu }} €<br /><b
                            >Muutosturva:</b
                        >
                        {{ activeUser.saldo.expired_muutosturva }} €
                    </p>
                </article>
            </div>
        </div>
                <div class="tile is-ancestor" v-if="activeUser.type != 'enduser'">
                    <section>

                    </section>
            <div class="tile is-parent is-12">
             
                <article class="tile is-child box">
                    <p class="title">
                        Muutosturvatilaukset
                    </p>
                 
                    <b-table :data="newOrders">
                        <b-table-column
                            field="company.name"
                            :label="$t('message.dashboard.list.company-name')"
                            width="40"
                            sortable
                            v-slot="props"
                        >
                            {{ props.row.company.name }}
                        </b-table-column>

                        <b-table-column
                            field="amount"
                            :label="$t('message.dashboard.list.amount')"
                            width="40"
                            sortable
                            v-slot="props"
                        >
                            {{ props.row.amount }}
                        </b-table-column>

                        <b-table-column
                            field="amount"
                            :label="$t('message.dashboard.list.duration')"
                            width="40"
                            sortable
                            v-slot="props"
                        >
                            {{ props.row.meta.duration }}
                        </b-table-column>

                        <b-table-column
                            field="created_at"
                            :label="$t('message.dashboard.list.created')"
                            width="100"
                            sortable
                            v-slot="props"
                        >
                            {{ _formatDate(props.row.created_at) }}
                        </b-table-column>

                        <b-table-column
                            field="validity_beginning"
                            :label="$t('message.dashboard.list.valid-from')"
                            width="100"
                            sortable
                            v-slot="props"
                        >
                            {{ _formatDate(props.row.validity_beginning) }}
                        </b-table-column>

                        <b-table-column
                            v-slot="props"
                            v-if="activeUser.type == 'admin'"
                        >
                            <b-button
                                @click="move_order_to(props.row.id, 'billed')"
                                >{{
                                    $t("message.dashboard.mark-billed")
                                }}</b-button
                            >
                        </b-table-column>
                    </b-table>
                </article>
            </div>
        </div>
        <div class="tile is-ancestor" v-if="activeUser.type != 'enduser'">
            <div class="tile is-parent is-12">
                <article class="tile is-child box">
                    <p class="title">
                        {{ $t("message.dashboard.billed-orders") }}
                    </p>
                    <b-table :data="billedOrders">
                        <b-table-column
                            field="company.name"
                            :label="$t('message.dashboard.list.company-name')"
                            width="40"
                            sortable
                            v-slot="props"
                        >
                            {{ props.row.company.name }}
                        </b-table-column>

                        <b-table-column
                            field="amount"
                            :label="$t('message.dashboard.list.amount')"
                            width="40"
                            sortable
                            v-slot="props"
                        >
                            {{ props.row.amount }}
                        </b-table-column>

                        <b-table-column
                            field="amount"
                            :label="$t('message.dashboard.list.duration')"
                            width="40"
                            sortable
                            v-slot="props"
                        >
                            {{ props.row.meta.duration }}
                        </b-table-column>

                        <b-table-column
                            field="created_at"
                            :label="$t('message.dashboard.list.created')"
                            width="100"
                            sortable
                            v-slot="props"
                        >
                            {{ _formatDate(props.row.created_at) }}
                        </b-table-column>

                        <b-table-column
                            field="validity_beginning"
                            :label="$t('message.dashboard.list.valid-from')"
                            width="100"
                            sortable
                            v-slot="props"
                        >
                            {{ _formatDate(props.row.validity_beginning) }}
                        </b-table-column>

                        <b-table-column
                            v-slot="props"
                            v-if="activeUser.type == 'admin'"
                        >
                            <b-button
                                @click="move_order_to(props.row.id, 'paid')"
                                >{{
                                    $t("message.dashboard.mark-paid")
                                }}</b-button
                            >
                        </b-table-column>
                    </b-table>
                </article>
            </div>
        </div>
        <div class="tile is-ancestor" v-if="activeUser.type != 'enduser'">
            <div class="tile is-parent is-12">
                <article class="tile is-child box">
                    <p class="title">
                        {{ $t("message.dashboard.paid-orders") }}
                    </p>
                    <b-table :data="paidOrders">
                        <b-table-column
                            field="company.name"
                            :label="$t('message.dashboard.list.company-name')"
                            width="40"
                            sortable
                            v-slot="props"
                        >
                            {{ props.row.company.name }}
                        </b-table-column>

                        <b-table-column
                            field="amount"
                            :label="$t('message.dashboard.list.amount')"
                            width="40"
                            sortable
                            v-slot="props"
                        >
                            {{ props.row.amount }}
                        </b-table-column>

                        <b-table-column
                            field="amount"
                            :label="$t('message.dashboard.list.duration')"
                            width="40"
                            sortable
                            v-slot="props"
                        >
                            {{ props.row.meta.duration }}
                        </b-table-column>

                        <b-table-column
                            field="created_at"
                            :label="$t('message.dashboard.list.created')"
                            width="100"
                            sortable
                            v-slot="props"
                        >
                            {{ _formatDate(props.row.created_at) }}
                        </b-table-column>

                        <b-table-column
                            field="validity_beginning"
                            :label="$t('message.dashboard.list.valid-from')"
                            width="100"
                            sortable
                            v-slot="props"
                        >
                            {{ _formatDate(props.row.validity_beginning) }}
                        </b-table-column>
                    </b-table>
                </article>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
export default {
    data() {
        return {};
    },

    computed: {
        user() {
            return this.$store.getters["getUser"];
        },
        newOrders() {
            return this.set_company(this.$store.getters["getNewOrders"]);
        },
        billedOrders() {
            return this.set_company(this.$store.getters["getBilledOrders"]);
        },
        paidOrders() {
            return this.set_company(this.$store.getters["getPaidOrders"]);
        },
    },
    methods: {
        getStats() {
            axios.get('/stats').then((response) => {
                console.log(response);
            })
        },
        getOrders() {
            axios.get("/orders/bystatus/new-order").then((response) => {
                console.log("OMG", response.data);
            });
        },
        set_company(orders) {
            var companies = this.$store.getters["getCompanies"];
            orders.forEach(async function (order) {
                order.company = companies.find(
                    (company) => company.id == order.company_id
                );
            });
            return orders;
        },
        move_order_to(id, _status) {
            this.$store
                .dispatch("changeOrderStatus", { id: id, status: _status })
                .then((response) => {
                    var status = response.status;
                    if (status >= 200 && status < 300) {
                        this.toastSuccess(
                            this.$t(
                                "message.dashboard.order.moved_to." +
                                    _status +
                                    ".success"
                            )
                        );

                        this.$store.dispatch("fetchNewOrders");
                        this.$store.dispatch("fetchBilledOrders");
                        this.$store.dispatch("fetchPaidOrders");
                        this.getStats();
                    } else {
                        this.toastFailure(
                            this.$t(
                                "message.dashboard.order.moved_to." +
                                    _status +
                                    ".error"
                            )
                        );
                    }
                })
                .catch((error) => {
                    this.ERROR(error);
                    this.toastFailure(
                        this.$t(
                            "message.dashboard.order.moved_to." +
                                _status +
                                ".error"
                        )
                    );
                });
        },
    },
    mounted() {
        this.getStats();
        if (this.user && this.user.type == "admin") {
            this.$store.dispatch("fetchNewOrders");
            this.$store.dispatch("fetchBilledOrders");
            this.$store.dispatch("fetchPaidOrders");
            this.$store.dispatch("fetchCompanies");
        }
    },
};
</script>
<style scoped>
.badge {
    background-color: #00d1b2;
    border: 2px solid transparent;
    border-radius: 14px;
    box-shadow: 0 0 0 2px #fff;
    color: #fff;
    font-size: .65rem;
    height: 14px;
    line-height: 8px;
    min-width: 14px;
    overflow: hidden;
    padding: 0.05rem 0.15rem;
    position: absolute;
    right: 0;
    text-overflow: ellipsis;
    top: 0;
    transform: translate(50%,-50%);
    white-space: nowrap;
}
</style>